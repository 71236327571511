import * as React from 'react';

import Layout from '../components/layout/layout';
import { Headline } from '../components/layout/typography';

export default function LegalPage() {
  return (
    <Layout title="Legal">
      <div className="relative py-32 bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <Headline className="text-7xl tracking-tight font-extrabold sm:text-9xl">
              Legal /<br />
              Impressum
            </Headline>
          </div>
          <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <h2>Legal Information / Angaben gemäß § 5 TMG</h2>
            <p>
              Timothy Krechel
              <br />
              Leostraße 18 <br />
              53844 Troisdorf <br />
              Germany / Deutschland
            </p>
            <h2>Contact Information / Kontakt</h2>
            <p>
              Phone / Telefon: +49 177 858 32 44 <br />
              E-Mail: hello@timothy.de
            </p>
            <h2>
              Responsible for Content /<br /> Verantwortlich für den Inhalt{' '}
              <br />
              <span>gemäß § 55 Abs. 2 RSTV</span>
            </h2>
            <p>
              Timothy Krechel
              <br />
              Leostraße 18 <br />
              53844 Troisdorf
              <br />
              Germany / Deutschland
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
